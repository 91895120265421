import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/LayoutPreowned'
import YouTube from '../../components/homepage/YouTube'
import ProductGrid from '../../components/products/ProductGrid'
import CartierCategoryBtns from '../../components/preowned/CartierCategoryBtns'

// markup
const CartierSantos = () => {
  const data = useStaticQuery(
    graphql`
      query queryCartierSantos {
        products: allStrapiProduct(
          filter: { brand: { eq: "Cartier" }, model: { regex: "/Santos/" }, qty: { gt: 0 } }
          sort: { fields: stock_date, order: DESC }
          limit: 8
        ) {
          nodes {
            ...ProductCard
          }
        }
      }
    `
  )
  return (
    <Layout
      siteTitle={'Used Cartier Santos Watches for Sale'}
      canonical={'/fine-watches/cartier/cartier-santos/'}
      description={
        'Grays & Sons are experts in buying and selling second hand Cartier Santos Watches with over 40 years of experience in the business'
      }
    >
      <div className="flex justify-center align-middle flex-col">
        <section>
          <div className="bg-gray-500 w-full flex justify-center">
            <a href="/fine-watches/cartier/filter/model/santos,santos-100,santos-demoiselle,santos-dumont/">
              <StaticImage
                src="../../images/preowned/preowned-certified-used-cartier-santos-header.png"
                alt="Pre-Owned Certified Used Cartier Santos Header"
                aria-label="Used Cartier Santos Site Header"
              />
            </a>
          </div>
        </section>

        <section className="p-5 lg:px-0 w-full flex justify-center">
          <div className="w-full md:w-3/4 lg:w-3/4 flex justify-center flex-col">
            <h1 className="pt-20">USED CARTIER SANTOS</h1>
            <h2>
              {' '}
              FIND PRE-OWNED, USED, VINTAGE, ANTIQUE, HEIRLOOM, ESTATE AND MODERN CARTIER SANTOS
              WATCHES AT GRAY AND SONS
            </h2>
            <h3>
              BUY, SELL, TRADE, AND REPAIR USED AND PRE OWNED CARTIER SANTOS WATCHES WITH GRAY AND
              SONS JEWELERS
            </h3>
            <p>
              We are the best real watch and jewelry store located in Surfside, Miami Beach,
              Florida. We're across from Bal Harbour Shops. Gray and Sons is known for our real
              watch and jewelry experts on-site, open six days a week, and that we're real, not a
              virtual store. Our customers say we are the #1 Miami used watch buyer and seller
              proudly assisting for the past 42 years. We're sellers and buyers of pre-owned Cartier
              Santos watches in Miami, Miami Beach, Coral Gables, Coconut Grove, South Beach, Bal
              Harbour, Surfside, Sunny Isles , Aventura, Fort Lauderdale and many more areas.
              <br />
              <br />
              Gray and Sons Jewelers has a full in-stock inventory of certified authentic Cartier
              Santos Watches.{' '}
              <a href="/fine-watches/cartier/cartier-santos/">www.GrayandSons.com</a> and &ensp;
              <a href="https://sellusyourjewelry.com/">sellusyourjewelry.com</a>
              , a division of Gray &amp; Sons, will buy your pre-owned and used Cartier Santos
              Watches for cash. Trusted for 42 years, Gray and Sons is the #1 place to go for
              selling and buying jewelry and watches. With a full service watch repair shop, choose
              Gray and Sons, even for battery replacement. We will get you the best price on
              trade-ins. Come see our showroom in Surfside, Florida. Talk to one of our
              representatives today. We are ready to make you a deal. Talk to a real person, Rich or
              Vika.
              <br />
              <br />
              How was one of the most revolutionary Cartier watches created? The Cartier Santos
              watch collection is named after Alberto Santos-Dumont, a Brazilian aviation pioneer.
              The watch is available in various options. For example, in 1978, the automatic variant
              Santos Sport was released. The common feature of all Santos watches is Roman numerals,
              giving them an elegant character. Many years have passed, and Cartier Santos is still
              one of the most desirable Cartier models. Time to get one for yourself from Gray and
              Sons. Gray and Sons is the best place to repair Cartier Santos Watches, to buy used
              Cartier Santos Watches and to sell your Cartier Santos watch.
              <br />
              <br />
              Knowing how much the value of luxury watches is growing, more and more people are
              investing in them. When it comes to Cartier, it is an excellent way to put your money
              in such a desirable item, especially if you buy one for an exceptional price. The most
              desirable Cartier models include Cartier Santos, Cartier Pasha, Cartier Ballon Bleu,
              and Cartier Tank. Are you thinking of buying a Cartier watch for yourself? That’s
              great if you know where to look for the best prices on the market and how to avoid
              waiting lists or unavailable models. Gray and Sons is the place. Popular Cartier
              Santos References include WJSA0008, WHSA0009, WHSA0016, WGSA0021, WGSA0019, W2SA0008,
              W2SA0011, WSSA0018, WSSA0009, WSSA0029, WSSA0030, WSSA0013, WSSA0037, WSSA0039,
              W2SA0016, WSSA0022, WHSA0018, WJSA0012, WJSA0014, WHSA0015, WGSA0018, W20121U2,
              WGSA0034, WGSA0017, WGSA0030, WJSA0010, WJSA0006, WGSA0029. A few great Cartier's
              Santos for sale from our inventory include Cartier Santos 24mm Limited Edition. Unique
              &amp; Limited! Vintage Cartier Santos Limited Edition watch is made out of yellow gold
              on a 18k White &amp; Yellow Gold bracelet with a 18k Deploy buckle. This Cartier watch
              has a 24 x 24 mm case. Another from our inventory is Cartier Santos 100 42mm
              Ref.W20090X8. Cartier Santos 100 XL Chronograph in stainless steel on a black
              alligator strap with deployant buckle. This Cartier watch has a 42 x 40 mm case with a
              Square caseback and Silver Roman Numeral dial.
              <br />
              <br />
              Gray &amp; Sons customers call us the #1 seller of pre-owned Cartier watches in the
              U.S. We have been specializing in selling luxury jewelry and certified watches for
              over 40 years. The vast inventory of diamond Cartier watches in yellow gold, stainless
              steel, and white gold is one of the reasons why the company is extremely valued. We
              offer certified authentic and certfied pre-owned watches. Gray &amp; Sons also
              provides professional luxury watch repairs for fine Swiss watches. That includes watch
              repair for Cartier watches with diamond bezel, Rolex, Omega, Patek Philippe, and
              pre-owned Cartier.
              <br />
              <br />
              For the best prices of certified pre-owned Cartier watch, check the available models
              on our website <a href="/">www.grayandsons.com</a>. Get more details by visiting Gray
              &amp; Sons&quote; Showroom in Surfside, Miami.Our watches have been recently serviced,
              freshly lubricated, timing adjusted and detailed. Come and see our highly qualified
              in-house watchmakers and jewelers who will provide you with every detail about the
              finest selection of used Cartier watch available at Gray &amp; Sons. People say, “I
              want to sell my Cartier Santos watch for cash.” If you want to sell your Cartier
              Santos watch for cash, go to &ensp;
              <a href="/">www.grayandsons.com</a> or{' '}
              <a href="https://sellusyourjewelry.com/">www.sellusyourjewelry.com</a>. Would you like
              to <a href="/chat-with-rich">CHAT NOW</a> with Viktoria or Rich? Vika and Rich are
              both real specialists ready to make a deal now. If you have any questions about our
              process or our products, reach out to a representative for more information.
              <br />
              <br />
            </p>
            <div className="w-full flex justify-start">
              <a href="/fine-watches/cartier/">
                <button>SHOP CARTIER WATCHES</button>
              </a>
            </div>
            <br />
            <br /> <br />
            <br />
          </div>
        </section>
      </div>

      <section>
        <h2 className="text-center py-10">IN-STOCK CARTIER WATCHES</h2>
        <ProductGrid products={data.products.nodes} />
      </section>

      <CartierCategoryBtns />

      <section className="py-20">
        <h2 className="text-center py-10">VISIT GRAY &amp; SONS ON YOUTUBE</h2>
        <div className="w-full flex justify-center">
          <YouTube
            className={'w-full lg:w-3/4 xl:w-1/2 py-10 aspect-video'}
            title={'You Tube Video Title '}
            src={'https://www.youtube.com/embed/KH7qSClYNhs'}
          />
        </div>
      </section>
    </Layout>
  )
}

export default CartierSantos
